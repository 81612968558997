import { Card, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useIndustryNewsQuery } from '../../redux/api/ws/dashboard/dashboard';
import { IndustryNews } from '../../@types/dashboard';
import { useCallback } from 'react';

export const DashboardIndustryNews = () => {
  // Function to check if a date is today
  function isToday(date: Date) {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  // Function to check if a date is yesterday
  function isYesterday(date: Date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  }

  const columns: ColumnsType<IndustryNews> = [
    {
      dataIndex: 'note',
      key: 'note',
      ellipsis: true,
    },
    {
      key: 'created',
      dataIndex: 'created',
      width: 80,
      render: (value) => {
        if (isToday(new Date(value))) {
          return 'Today';
        }
        if (isYesterday(new Date(value))) {
          return 'Yesterday';
        }
        return new Date(value).toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      },
      ellipsis: true,
    },
  ];

  const { data, isLoading } = useIndustryNewsQuery({});
  const getData = useCallback(() => {
    if (!data?.data) return [];
    if (typeof data?.data === 'object') return Object.values(data?.data || {});
    return data.data;
  }, [data]);

  return (
    <Card
      style={{ marginTop: 24 }}
      size="small"
      className="dashboard-base-card"
      bordered={false}
    >
      <span className="base-card-title">INDUSTRY NEWS</span>
      <Table
        size="small"
        loading={isLoading}
        className="dashboard-table"
        id="dashboard-industry-new-table"
        style={{ height: 287 }}
        pagination={false}
        showHeader={false}
        rowKey="guid"
        columns={columns}
        dataSource={getData()}
        rowClassName="selectRowClassName"
        onRow={(record) => {
          return {
            onClick: () => window.open(record.url, '_blank'),
          };
        }}
        scroll={{ y: 282 }}
      />
    </Card>
  );
};
