import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

declare global {
  interface Window {
    Echo: Echo;
    Pusher: Pusher;
  }
}

export const initSocket = () => {
  if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      wsHost: window.location.hostname,
      wsPort: 6001,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      forceTLS: false,
      disabledStatus: true,
      Pusher: Pusher,
    });
  }
};
